import React from "react"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import DisolveImageFx from "./disolveImageFx"
import ReadMoreReact from "../readMore/ReadMore"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"

export const SectionEnjeux = props => {
  const LANG = useStore(state => state.language)

  const post = props.data
  if (post === undefined) return
  const disolve_image = getImageSrcFromWpData(post.projet_enjeux_field.image)

  return (
    <section className="section-enjeux section-white">
      <div className="contentMainContainer full-grid">
        <div className="thumbnail-enjeux">
          <DisolveImageFx image={disolve_image} />
        </div>
        <h2 className="section-title-medium">{LANGUAGES[LANG].valeurTitle}</h2>
        <p
          className="text-focus"
          dangerouslySetInnerHTML={{
            __html: post.projet_enjeux_field.text1,
          }}
        />
        <div
          className="text-2"
          // dangerouslySetInnerHTML={{
          //   __html: post.projet_enjeux_field.text2,
          // }}
        >
          <ReadMoreReact
            min={420}
            ideal={470}
            max={500}
            text={post.projet_enjeux_field.text2}
            readMoreText={LANGUAGES[LANG].readMore}
            readLessText={LANGUAGES[LANG].readLess}
          />
        </div>
      </div>
    </section>
  )
}
