import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useAnimationFrame } from "../../@utils/useAnimationFrame"
import gsap from "gsap"
import { Draggable } from "gsap/all"
import { isMobile } from "../../@utils/isMobile"
import { InertiaPlugin } from "../../vendors/gsap/InertiaPlugin"
import { sleep } from "../../@utils/utils"

// let speeds = []
const templateTop = [0.2, 0.3, 0.1, 0.25, 0.1]
let totalWidth = 0
const speed = 1

const animation = element => {
  let left = element.getBoundingClientRect().left - speed + "px"
  const elWidth = element.getBoundingClientRect().width
  // si hors du screen remettre en bout
  if (element.getBoundingClientRect().left < -elWidth) {
    element.style.left = totalWidth - elWidth + "px"
  } else {
    element.style.left = left
  }
}

const getLeft = (item, previousEl) => {
  if (previousEl === null) return "0px"
  // const offset = (- 40 * Math.random()) + 50
  const offset = -50
  const left =
    previousEl.getBoundingClientRect().left +
    previousEl.getBoundingClientRect().width
  return left + offset + "px"
}

const getTop = (index, container, image) => {
  // get height of the container
  const ratio = templateTop[index % 5]
  const maxHeight = container.clientHeight
  const itemHeight = image.clientHeight
  let top = Math.round(ratio * maxHeight)
  if (top > maxHeight - itemHeight - 40) top = maxHeight - itemHeight
  top = Math.round(top)

  return top + "px"
}

export const Portfolio = ({ children, isAllImagesLoaded }) => {
  const ref = useRef(null)
  const [start, setStart] = useState(false)
  const reSizeContainer = async () => {
    await sleep(2000)
    ref.current.style.height = "800px"
    ref.current.style.overflow = "unset"
  }

  useLayoutEffect(() => {

    gsap.registerPlugin(Draggable, InertiaPlugin)
    const wrapper = document.querySelector(".draggable-wrapper")
    const boxes = document.querySelector(".boxes")
    const proxy = document.createElement("div")
    const numBoxes = boxes.children.length
    let boxWidth = Math.floor(window.innerWidth / 2.8)
    if (isMobile())
      boxWidth = Math.floor(window.innerWidth / 1.2)

    let boxHeight = 700
    const wrapWidth = numBoxes * boxWidth
    const viewWidth = window.innerWidth
    const wrapVal = gsap.utils.wrap(0, wrapWidth)


    // gsap.set([wrapper], { height: boxHeight })
    gsap.set(boxes, { left: -boxWidth })

    for (let i = 0; i < numBoxes; i++) {
      const box = boxes.children[i ]
      // gsap.set(box, { x: i * boxWidth, y: getTop(i, ref.current, box),  width: boxWidth, height: 700 })
      gsap.set(box, { x: i * boxWidth, y: getTop(i, ref.current, box),  width: boxWidth, height: boxHeight })
    }

    const animation = gsap.to(".box", {
      duration: 1,
      x: `+=${wrapWidth}`,
      ease: "none",
      paused: true,
      modifiers: {
        x: function (x, target) {
          x = parseInt(x) % wrapWidth
          target.style.visibility =
            x - boxWidth > viewWidth ? "hidden" : "visible"
          return `${x}px`
        },
      },
    })

    let draggable = new Draggable(proxy, {
      type: "x",
      trigger: ".draggable-wrapper",
      inertia: true,
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        x: x => {
          return Math.round(x / boxWidth) * boxWidth
        },
      },
    })

    window.addEventListener("resize", resize)

    function resize() {
      // viewWidth = viewport.offsetWidth
      animation.render(animation.time(), false, true)
    }

    function updateProgress() {
      animation.progress(wrapVal(this.x) / wrapWidth)
    }
  }, [isAllImagesLoaded])


  return (
    <div className="brand-galery-slider">
      <div className="galery-container draggable-wrapper" ref={ref}>
        <div className="boxes">
          {React.Children.map(children, (image, index) => {
            return (
              <div className="item-sroll box" id={`${index}`}>
                {image}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

// Mini video
export const Video = ({ url, onLoad }) => {
  return (
    <video autoPlay muted loop style={{ width: 500 }} onLoadedData={onLoad}>
      <source src={url} type="video/mp4" />
    </video>
  )
}
