import React from "react"
import icicle_logo from "../../../static/marques/icicle_logo.png"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"
import { PictoGalery } from "./BrandFooter"

export const SectionIntroduction = props => {
  const LANG = props.lang
  const post = props.data
  if (post === undefined) return

  const logo = getImageSrcFromWpData(post.projet_introduction_field.logo)

  return (
    <section className="section-introduction ">
      <div className="contentMainContainer full-grid">
        <div className="intro-brand-logo">
          {logo !== null ? (
            <img src={logo} />
          ) : (
            <h1 className="brand-title">{post.title}</h1>
          )}
        </div>
        <div className="bloc-content">
          <div
            dangerouslySetInnerHTML={{
              __html: post.projet_introduction_field.description,
            }}
          />
          <div className="brand-detail">
            <div className="detail-city">
              <label>{LANGUAGES[LANG].origine}</label>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.projet_introduction_field.city,
                }}
              />
            </div>
            <div className="detail-date-creation">
              <label>{LANGUAGES[LANG].creation}</label>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.projet_introduction_field.dateOfCreation,
                }}
              />
            </div>
            <div className="detail-category">
              <label>{LANGUAGES[LANG].secteur}</label>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.secteurs.nodes[0].name,
                }}
              />
            </div>
          </div>

          <div className="backToGalery">
            <Link to="/entreprises">
              <div className="picto-container">
                <PictoGalery />
              </div>
              {LANGUAGES[LANG].goBackGalery_1} {LANGUAGES[LANG].goBackGalery_2}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
