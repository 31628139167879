import React, { useEffect, useRef } from "react"
import header_image_1 from "../../../static/marques/icicle_header_1.jpg"
import header_image_2 from "../../../static/marques/icicle_header_2.jpg"

import gl from "../../@core/scenes/brand/brandHeaderFx" // singleton

export const HeaderImagesFx = props => {
  const canvasRef = useRef()

  let header_image_bg = header_image_1
  let header_image_product = header_image_2

  if (props.data.projet_header_field.headerImage !== null) {
    header_image_bg =
      props.data.projet_header_field.headerImage.localFile.childImageSharp
        .gatsbyImageData.images.fallback.src
    header_image_product =
      props.data.projet_header_field.headerImage2.localFile.childImageSharp
        .gatsbyImageData.images.fallback.src
  }

  useEffect(() => {
    canvasRef.current.appendChild(gl.domElement)
    // enable RAF
    gl.enable()

    let scrollY = 0
    // desactive quand on scroll
    window.addEventListener("scroll", e => {
      scrollY = window.scrollY
      if (scrollY > 780) {
        gl.enabled = false
      } else {
        gl.enabled = true
      }
    })

    return () => {
      // unMount & Disable RAF
      // if (canvasRef.current)
      //   while (canvasRef.current.lastChild) {
      //     canvasRef.current.removeChild(canvasRef.current.lastChild)
      //   }
      gl.disable()
    }
  }, [])

  // TODO: On setup qu'une seule fois dans le site
  useEffect(() => {
    // qu'une seule fois dans le lancement du site
    gl.setup(canvasRef.current)
    gl.init([header_image_product])
  }, [])

  return (
    <div className="header-images-fx-container ">
      <div ref={canvasRef} className="brand-header-canvas contentMainContainer">
        <img src={header_image_bg} className="brand-header-first-image" />
      </div>
    </div>
  )
}
