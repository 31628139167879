import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { HeaderImagesFx } from "../components/brands/headerImagesFx"
import { SectionEnjeux } from "../components/brands/sectionEnjeux"
import { SectionIntroduction } from "../components/brands/sectionIntroductions"
import { SectionHistorique } from "../components/brands/sectionHistorique"
import { SectionPresentation } from "../components/brands/sectionPresentation"
import { SectionExpertAndForces } from "../components/brands/sectionExpertAndForces"
import { BrandGalery } from "../components/brands/brandGalery"
import { useStore } from "../@core/scenes/mainStore"
import { VideoFull } from "../components/brands/videoFull"
import { SectionAnecdote } from "../components/brands/sectionAnecdote"
import { BrandFooter } from "../components/brands/BrandFooter"
import { Helmet } from "react-helmet"

export default function Brand({ data }) {
  const post = data.single.nodes[0]
  const translationSlug = post.translations[0] ? post.translations[0].slug : ""
  const visionnaires = data.visionnaires.nodes
  let relatedPosts = "Mode"
  // Post Language
  const LANG = post.language.code
  useEffect(() => {
    useStore.setState({ currentTranslationSlug: translationSlug })
    useStore.setState({ slugLanguage: LANG })
  }, [LANG, translationSlug])

  // Query other brands in the same Language
  if (data.sameSecteur.nodes[0].secteurs.nodes[0] !== undefined)
    relatedPosts = data.sameSecteur.nodes[0].secteurs.nodes[0].entreprises.nodes.filter(
      value => value.language.code === LANG
    )

  const allEntreprises = data.allEntreprises.nodes.filter(
    value => value.language.code === LANG
  )

  const expert = post.projet_section_presentation_slider.notreExpert
  const forces = post.projet_section_presentation_slider.forcesQuality
  const portfolio = post.projet_portfolio.portfolio
  const socialMedia = post.projet_portfolio.socialMedia

  // Logo color
  useEffect(() => {
    useStore.setState({ theme: "black" })
    // pich page color and change Theme color
    const themeColors = post.projet_introduction_field.themeColor
    document.documentElement.style.setProperty(
      "--brandPrimary",
      themeColors.couleur1
    )
    document.documentElement.style.setProperty(
      "--brandSecondary",
      themeColors.couleur2
    )
    document.documentElement.style.setProperty(
      "--brandThird",
      themeColors.couleur3
    )
    if (themeColors.couleur4) {
      document.documentElement.style.setProperty(
        "--brandFour",
        themeColors.couleur4
      )
    }
  }, [post.projet_introduction_field.themeColor])

  return (
    <Layout localClass="page-brand" showPointClouds={false}>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <HeaderImagesFx data={post} />
      <SectionIntroduction data={post} lang={LANG} />
      <SectionEnjeux data={post} />
      <VideoFull data={post} />
      <SectionHistorique data={post} />
      <SectionPresentation data={post} />
      <SectionExpertAndForces data={expert} forcesData={forces} />
      <BrandGalery
        data={portfolio}
        dataPost={post}
        socialMedia={socialMedia}
        url={post.projet_introduction_field.brandUrl}
      />
      <SectionAnecdote data={post} />
      <BrandFooter
        data={post}
        relatedPosts={relatedPosts}
        visionnaires={visionnaires}
        allEntreprises={allEntreprises}
      />
    </Layout>
  )
}

// MAIN QUERY DATA
export const query = graphql`
  query($slug: String!) {
    single: allWpEntreprise(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          code
        }
        translations {
          slug
        }
        title
        slug
        secteurs {
          nodes {
            name
            slug
          }
        }
        MarketPlace {
          marketplaceUrl
        }
        anecdote_paris1618 {
          anecdoteContent
          phraseBandeau
        }
        media_video_full {
          mediaVideo {
            mediaItemUrl
          }
        }
        projet_introduction_field {
          description
          dateOfCreation
          city
          brandUrl
          themeColor {
            couleur1
            couleur2
            couleur3
            couleur4
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, quality: 90)
              }
            }
          }
        }
        projet_header_field {
          headerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1466)
              }
            }
          }
          headerImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1466, quality: 70)
              }
            }
          }
        }
        projet_historical {
          historicalDate {
            description
            date
          }
        }
        projet_section_presentation_slider {
          title
          presentationText1
          enChiffres {
            content
            numberLabel
          }
          forcesQuality {
            title
            content
            icon {
              sourceUrl
            }
          }
          notreExpert {
            content
            title
            expertPhoto {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 388)
                }
              }
            }
          }
          sliderImages {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, quality: 90)
                }
              }
            }
          }
        }
        projet_portfolio {
          socialMedia {
            socialChannel
            socialUrl
          }
          portfolio {
            video {
              localFile {
                url
              }
            }
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
        }
        projet_enjeux_field {
          titre
          text2
          text1
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 792)
              }
            }
          }
        }
      }
    }
    allEntreprises: allWpEntreprise {
      nodes {
        language {
          code
        }
        title
        slug
        secteurs {
          nodes {
            name
          }
        }
        projet_introduction_field {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, quality: 90)
              }
            }
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    sameSecteur: allWpEntreprise(filter: { slug: { eq: $slug } }) {
      nodes {
        secteurs {
          nodes {
            entreprises {
              nodes {
                language {
                  code
                }
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                projet_introduction_field {
                  logo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    visionnaires: allWpVisionnaire {
      nodes {
        visionnaire_post {
          entrepriseLinkTest {
            ... on WpEntreprise {
              id
              slug
              secteurs {
                nodes {
                  name
                }
              }
            }
          }
        }
        slug
      }
    }
  }
`
