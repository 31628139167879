import { useTransition, animated, useSpring } from "react-spring"
import { Link } from "gatsby"
import React, { useState } from "react"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"

export const SectionExpertAndForces = props => {
  const LANG = useStore(state => state.language)

  let post = props.data
  const forcesData = props.forcesData
  if (post === undefined || forcesData === undefined) return

  const expertImg = getImageSrcFromWpData(post.expertPhoto)

  return (
    <section className="section-expert-and-forces full-grid">
      {post.content && (
        <div className="bloc-expert contentMainContainer">
          <h2 className="section-title-medium">{LANGUAGES[LANG].aWordFromCommittee}</h2>
          <div
            className="expert-content"
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>

          {/* <Link to="/" className="link-brand-website">
          Découvrez notre expert.e
        </Link> */}
          {expertImg && (
            <div className="expert-thumbnail">
              <img src={expertImg} alt="" />
            </div>
          )}
        </div>
      )}

      {forcesData && (
        <div className="bloc-forces full-grid contentMainContainer">
          <h2 className="section-title-medium">{LANGUAGES[LANG].keyStrengths_1}<br/>{LANGUAGES[LANG].keyStrengths_2}</h2>
          <div className="forces-items-container">
            {forcesData.map((value, index) => {
              return (
                <Force
                  title={value.title}
                  content={value.content}
                  iconUrl={value.icon && value.icon.sourceUrl}
                  key={index}
                />
              )
            })}
          </div>
        </div>
      )}
    </section>
  )
}

const Force = ({ title, content, iconUrl }) => {
  const [toggle, set] = useState(true)

  const onClickHandler = () => {
    set(!toggle)
  }

  const transitions = useTransition(toggle, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <div className="forces-item">
      {transitions((styles, item) => {
        return item ? (
          <animated.h3 style={styles}>
            {iconUrl && (
              <div className="icon">
                <img src={iconUrl} />
              </div>
            )}
            {title}
          </animated.h3>
        ) : (
          <animated.p style={styles}>{content}</animated.p>
        )
      })}
      {/* <SwitchButton onPress={onClickHandler} toggle={toggle} /> */}
    </div>
  )
}

const SwitchButton = ({ onPress, toggle }) => {
  const styles = useSpring({
    transform: toggle ? "rotate(0deg)" : "rotate(45deg)",
  })

  return (
    <animated.div onClick={onPress} className="switch-button" style={styles}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="16.0385" cy="16" rx="16.0385" ry="16" fill="#F3F1EF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0388 12C15.5959 12 15.2369 12.359 15.2369 12.8019V15.2H12.8293C12.3875 15.2 12.0293 15.5581 12.0293 16C12.0293 16.4418 12.3875 16.8 12.8293 16.8H15.2369V19.1981C15.2369 19.641 15.5959 20 16.0388 20C16.4817 20 16.8408 19.641 16.8408 19.1981V16.8H19.2486C19.6904 16.8 20.0486 16.4418 20.0486 16C20.0486 15.5581 19.6904 15.2 19.2486 15.2H16.8408V12.8019C16.8408 12.359 16.4817 12 16.0388 12Z"
          fill="black"
        />
      </svg>
    </animated.div>
  )
}
