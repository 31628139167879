import { useTransition, animated, useSpring } from "react-spring"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import {
  SVGFacebook,
  SVGInstagram,
  SVGLinkedIn,
  SVGTwitter,
  SVGYouTube,
  SVGPinterest,
} from "../icoSocial"
import { Portfolio, Video } from "./PorfolioSlider"
import { DragAndDropUI } from "../ui/dragAndDrop"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"
import { PictoShop } from "./BrandFooter"

export const BrandGalery = props => {
  const LANG = useStore(state => state.language)
  let post = props.data
  const BrandUrl = props.url
  const [loaded, setLoaded] = useState(false)
  const elementsLoaded = useRef(0)
  const socialMedia = props.socialMedia
  const dataPost = props.dataPost
  // TODO: Fix to erase ()
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (post === undefined || post === null) return null
  // how many files do we have to load? sometimes the backend send null for video or media item,
  // do not count them
  const images = post.filter(value => value.media !== null)
  const video = post.filter(value => value.video !== null)

  const nbElementToLoad = images.length + video.length

  const onImageLoadHandler = e => {
    elementsLoaded.current = elementsLoaded.current + 1
    if (elementsLoaded.current === nbElementToLoad - 1) setLoaded(true)
  }

  let urlMarketPlace = dataPost.MarketPlace.marketplaceUrl

  return (
    <section className="section-brand-galery">
      {post.length > 0 && (
        <Portfolio isAllImagesLoaded={loaded}>
          {video.map((value, index) => {
            return (
              <Video
                key={index}
                url={value.video.localFile.url}
                onLoad={onImageLoadHandler}
              />
            )
          })}
          {images.map((value, index) => {
            return (
              <img
                key={index}
                src={getImageSrcFromWpData(value.media)}
                onLoad={onImageLoadHandler}
              />
            )
          })}
        </Portfolio>
      )}
      {post.length > 0 && <DragAndDropUI />}
      <div className="bloc-social full-grid">
        <h3>
          {LANGUAGES[LANG].follow_1}
          <br />
          {LANGUAGES[LANG].follow_2}
        </h3>

        <div className="socialBrandAndLinks">
          <div>
            {socialMedia && (
              <div className="socialLinks">
                {socialMedia.map((value, index) => {
                  const socialChannel = value.socialChannel
                  let svg = <SVGFacebook />

                  switch (socialChannel) {
                    case "facebook":
                      svg = <SVGFacebook />
                      break
                    case "instagram":
                      svg = <SVGInstagram />
                      break
                    case "linkedin":
                      svg = <SVGLinkedIn />
                      break
                    case "pinterest":
                      svg = <SVGPinterest />
                      break
                    case "twitter":
                      svg = <SVGTwitter />
                      break
                    case "youtube":
                      svg = <SVGYouTube />
                      break
                  }

                  return (
                    <a
                      href={value.socialUrl}
                      className="social-btn"
                      target="_blank"
                      key={index}
                    >
                      {svg}
                    </a>
                  )
                })}
              </div>
            )}
            {BrandUrl && (
              <a href={BrandUrl} target="_blank" className="link-brand-website">
                {LANGUAGES[LANG].visitWebsite}
              </a>
            )}
          </div>

          {urlMarketPlace !== null && (
            <a href={urlMarketPlace} target="_blank" className="marketPlace">
              <div className="picto-container">
                <PictoShop />
              </div>
              <br />
              {LANGUAGES[LANG].desireToBuy_1}
              <br />
              {LANGUAGES[LANG].desireToBuy_2}
            </a>
          )}
        </div>
      </div>
    </section>
  )
}
