import React, { useState } from "react"
import { useTransition, animated } from "react-spring"

const Slider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const onClickHandler = index => {
    setCurrentIndex(index)
  }

  const transitions = useTransition(currentIndex, {
    keys: null,
    from: {
      opacity: 0,
      transform: "translate3d(10%,0,0)",
      position: "absolute",
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(0%,0,0)" },
  })

  return (
    <div className="slider-portait-container">
      <div
        className="slider-items"
        onClick={() => onClickHandler((currentIndex + 1) % children.length)}
      >
        {transitions((styles, index) => {
          return <animated.div style={styles}>{children[index]}</animated.div>
        })}
      </div>

      <div className="slider-navigation">
        {React.Children.map(children, (child, index) => {
          const active = index === currentIndex
          return (
            <div
              className={`dot ${active && "current"}`}
              onClick={() => onClickHandler(index)}
            ></div>
          )
        })}
      </div>
    </div>
  )
}

export default Slider
