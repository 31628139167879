import React from "react"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"

export const SectionAnecdote = props => {
  const LANG = useStore(state => state.language)

  const post = props.data
  if (post === undefined || post === null) return null

  return (
    <section className="section-anecdote">
      <h3>{LANGUAGES[LANG].anecdoteParis}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: post.anecdote_paris1618.anecdoteContent,
        }}
      />
      <BanderoleText text={post.anecdote_paris1618.phraseBandeau} />
    </section>
  )
}

const BanderoleText = ({ text }) => {
  return (
    <div
      className="banderole-text"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  )
}
