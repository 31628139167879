import React from "react"
import PropTypes from "prop-types"
import trimText from "./trimText"


export default class ReadMoreReact extends React.Component {

  constructor(props) {
    super(props)

    let args = [
      this.props.text,
      this.props.min,
      this.props.ideal,
      this.props.max,
    ]

    const [primaryText, secondaryText] = trimText(...args)
    this.state = {
      displaySecondary: false,
      primaryText,
      secondaryText,
      readMoreText: this.props.readMoreText,
      readLessText: this.props.readLessText,
    }
  }

  setStatus() {
    let display = !this.state.displaySecondary
    this.setState({ displaySecondary: display })
  }

  render() {
    let displayText
    if (!this.state.secondaryText) {
      displayText = (
        <div className="display-text-group  read-more-group">
          <span
            className="displayed-text"
            dangerouslySetInnerHTML={{
              __html: this.props.text,
            }}
          />

        </div>
      )
    } else if (this.state.displaySecondary) {
      displayText = (
        <div className="display-text-group  read-more-group">
          <span
            className="displayed-text full-text"
            onClick={this.setStatus.bind(this)}
            dangerouslySetInnerHTML={{
              __html: this.props.text,
            }}
          />
          <div
              className="read-more-button"
              onClick={this.setStatus.bind(this)}
            >
              {this.state.readLessText}
          </div>
        </div>
      )
    } else {
      displayText = (
        <div className="display-text-group read-more-group">
          <span className="displayed-text">
            <span
              dangerouslySetInnerHTML={{
                __html: this.state.primaryText + "...",
              }}
            />
            <div
              className="read-more-button"
              onClick={this.setStatus.bind(this)}
            >
              {this.state.readMoreText}
            </div>
          </span>
        </div>
      )
    }

    return displayText
  }
}

ReadMoreReact.propTypes = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
  readMoreText: PropTypes.string,
  readLessText: PropTypes.string,
}

ReadMoreReact.defaultProps = {
  readMoreText: "read more",
  readLessText: "Read less"
}
