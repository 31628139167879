import React, { useEffect, useRef } from "react"
import Layout from "../../components/layout"
// import gl as singleton + setup is called in parallel
import gl from "../../@core/scenes/brand/brandDisolveImageFx" // singleton

export default function DisolveImageFx(props) {
  const image = props.image
  const canvasRef = useRef()

  useEffect(() => {
    canvasRef.current.appendChild(gl.domElement)
    // only once
    if (!gl.isReady) gl.setup(canvasRef.current)

    gl.init(image, canvasRef.current)
    // enable RAF
    gl.enable()

    // Déclencheur de l'animation du canvas resolve
    function scrollEventHandler() {
      // get clientTop
      if (!gl.figure) return
      const canvasPosY = canvasRef.current.getBoundingClientRect().y
      const scrollY = window.scrollY
      if (
        scrollY >
        canvasPosY + Math.round(canvasRef.current.clientHeight * 0.5)
      ) {
        if (!gl.figure.isStarted)  {
          gl.figure.start()
          // remove the listener
          window.removeEventListener("scroll", scrollEventHandler)
          // wait 2s and stop render
          setTimeout(() => {  gl.disable(); }, 2000);
        }
      }
    }

    window.addEventListener("scroll", scrollEventHandler)

    return () => {
      // unMount & Clean Canvas container & Disable RAF
      window.removeEventListener("scroll", scrollEventHandler)
      gl.disable()
      if (canvasRef.current)
        while (canvasRef.current.firstChild) {
          canvasRef.current.removeChild(canvasRef.current.firstChild)
        }
      // remove EventListener on Scroll
    }
  }, [])

  return (
    <div ref={canvasRef} className="canvasDisolveFx" id="disolveImage"></div>
  )
}
