import React, { useLayoutEffect, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "./slider"
import { getImageSrcFromWpData } from "../../@utils/wpData.utils"
import { sleep } from "../../@utils/utils"
import ReadMoreReact from "../readMore/ReadMore"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"

export const SectionPresentation = props => {
  const LANG = useStore(state => state.language)
  let post = props.data

  const reSizeContainer = async () => {
    await sleep(1000)
    // resize height of
    const sliderItems = document.querySelector(
      ".slider-portait-container .slider-items"
    )
    if(sliderItems) sliderItems.style.height = sliderItems.firstChild.clientHeight + "px"
  }

  useLayoutEffect(() => {
    reSizeContainer()
  })

  if (post === undefined || post === null) return null

  let sliderImagesData = post.projet_section_presentation_slider.sliderImages
  const EnChiffres = post.projet_section_presentation_slider.enChiffres

  // check if a media image is null
  if (sliderImagesData !== null)
    sliderImagesData = sliderImagesData.filter( obj => obj.image !== null)


  return (
    <section className="section-presentation">
      <div className="contentMainContainer full-grid">
        <h2
          className="section-title-medium"
          dangerouslySetInnerHTML={{
            __html: post.projet_section_presentation_slider.title,
          }}
        />
        <div className="presentation">
          <ReadMoreReact
            text={post.projet_section_presentation_slider.presentationText1}
            readMoreText="Lire plus"
          />
        </div>

        <Slider>
          {sliderImagesData !== null &&
            sliderImagesData.map((value, index) => {

                return (
                  <div key={index} className="slider-item">
                  <img src={getImageSrcFromWpData(value.image)} onLoad={reSizeContainer} />
                </div>
              )

            })}
        </Slider>

        {EnChiffres && EnChiffres.length > 0 && (
          <div className="en-chiffres">
            <h2 className="section-title-medium">{LANGUAGES[LANG].inFigures}</h2>
            <div className="en-chiffres-items-container">
              {EnChiffres.map((value, index) => {
                return (
                  <div key={index} className="en-chiffres-item">
                    <span>{value.numberLabel}</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: value.content,
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
