import * as THREE from "three"
import { Mesh, ShaderMaterial } from "three"
import Figure from "../../figure/FigureScroll"

class DisolveImageFx {
  constructor() {
    this.textureLoader = new THREE.TextureLoader()
    this.enabled = false
    if (typeof window !== `undefined`) {
      this.domElement = document.createElement("CANVAS")
    }
    this.texturesUrl = "/marques/icicle_homme_plage.png"
    this.textures = null
    this.figure = null
    // Put every EventListener in a local variable to remove it on unmount later
    this.ResizeHandler_Bound = this.resizeHandler.bind(this)
  }

  // Init Call From Outside
  setup(rootDom) {
    this.rootDom = rootDom
    this.sizes = {
      width: rootDom.clientWidth,
      height: rootDom.clientHeight,
    }
    // EventListener
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.ResizeHandler_Bound)
    }
    this.scene = new THREE.Scene()

    // Base camera
    this.camera = new THREE.OrthographicCamera(
      this.sizes.width / -2,
      this.sizes.width / 2,
      this.sizes.height / 2,
      this.sizes.height / -2,
      1,
      1000
    )
    this.camera.position.z = 1
    this.scene.add(this.camera)

    /**
     * Renderer
     */
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.domElement,
      alpha: true,
    })

    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)
  }

  async init(textureUrl, rootDom) {
    this.rootDom = rootDom
    if (textureUrl === null) textureUrl = "/marques/icicle_homme_plage.png"
    // loader
    await this.loadAssets(textureUrl)

    // when the texture is loaded
    if (this.figure === null)
      this.figure = new Figure(this.scene, this.textures[0], rootDom)
    else this.figure.changeTexture(this.textures[0], rootDom) // new texture

    this.isReady = true
    this.render()
  }

  async loadAssets(textureUrl) {
    const promiseArray = []
    promiseArray.push(this.textureLoader.loadAsync(textureUrl))
    this.textures = await Promise.all(promiseArray)
  }

  //  EVENTLISTENERS
  // **************************************************************
  resizeHandler() {
    // Update sizes
    this.sizes.width = this.rootDom.clientWidth
    this.sizes.height = this.rootDom.clientHeight

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)
  }
  removeEventListeners() {
    window.removeEventListener("resize", this.ResizeHandler_Bound)
  }
  // update - raf
  // **************************************************************
  render() {
    if (this.enabled && this.isReady) {
      // update material
      this.figure.udpate()
      // Render
      this.renderer.render(this.scene, this.camera)
      // Call tick again on the next frame
      this.rafID = window.requestAnimationFrame(this.render.bind(this))
    }
  }

  enable() {
    this.enabled = true
    this.render()
  }
  // UnMount
  //****************************************************************** */
  disable() {
    // Kill the Request Animation Frame
    window.cancelAnimationFrame(this.rafID)
    this.removeEventListeners()
    //TODO: pause the RAF
    this.enabled = false
  }
}

const gl = new DisolveImageFx()

export default gl
