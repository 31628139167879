import * as THREE from "three"
import Figure from "../../figure/Figure"

class Scene06 {
  constructor() {
    this.textureLoader = new THREE.TextureLoader()
    this.enabled = false
    this.loadAssetsLoaded = false
    this.figure = null

    this.sizes = {
      width: 0,
      height: 0,
    }
    if (typeof window !== `undefined`) {
      this.domElement = document.createElement("CANVAS")
    }

    this.texturesUrl = null // ["/produits/icicle_1.jpg", "/produits/icicle_2.jpg"]
    this.textures = null
    this.clock = new THREE.Clock()
    // CUSTOM SETTINGS
    this.SETTINGS = {}
    // this.mousePos = new THREE.Vector2(0, 0)
    // Put every EventListener in a local variable to remove it on unmount later
    // this.MouseMoveHandler_Bound = this.mouseMoveHandler.bind(this)
    this.ResizeHandler_Bound = this.resizeHandler.bind(this)
    this.lookAt = new THREE.Vector3(0, -50, 0)
  }

  // Init Call From Outside
  setup(domContainer) {
    this.domContainer = domContainer
    // width de base : 1466
    // height de base : 780
    // ratio : height = 53.2% de width
    this.sizes = {
      width: domContainer.clientWidth,
      height: Math.round(domContainer.clientWidth * 0.538),
    }
    // EventListener
    if (typeof window !== `undefined`) {
      document.addEventListener("mousemove", this.MouseMoveHandler_Bound)
      window.addEventListener("resize", this.ResizeHandler_Bound)
    }

    this.scene = new THREE.Scene()

    // Base camera
    this.camera = new THREE.OrthographicCamera(
      this.sizes.width / -2,
      this.sizes.width / 2,
      this.sizes.height / 2,
      this.sizes.height / -2,
      1,
      500
    )
    this.camera.position.z = 1
    this.scene.add(this.camera)

    /**
     * Renderer
     */
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.domElement,
      alpha: true,
      antialias: true,
    })

    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)

  }

  async init(datas) {
    this.texturesUrl = datas
    await this.loadAssets()
    this.loadAssetsLoaded = true
    this.addObjects()
    this.render()
  }

  async loadAssets() {
    const promiseArray = []
    for (let i = 0; i < this.texturesUrl.length; i++) {
      promiseArray.push(this.textureLoader.loadAsync(this.texturesUrl[i]))
    }
    this.textures = await Promise.all(promiseArray)
  }

  //  Add Figure to the Scene
  // **************************************************************
  addObjects() {
    // TODO: ne pas créer de nouvelle figure à chaqeu fois, mais update les textures
    this.figure = new Figure(this.scene, this.textures[0], this.domContainer)
  }

  updateTextures() {}

  //  EVENTLISTENERS
  // **************************************************************
  // mouseMoveHandler(e) {
  //   this.mousePos.x = e.clientX
  //   this.mousePos.y = e.clientY
  // }
  resizeHandler() {
    // Update sizes
    this.sizes.width = this.domContainer.clientWidth
    this.sizes.height = this.domContainer.clientHeight

    // update figure
    this.figure.onResize(this.sizes.width, this.sizes.height)

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)
  }
  removeEventListeners() {
    // document.removeEventListener("mousemove", this.MouseMoveHandler_Bound)
    window.removeEventListener("resize", this.ResizeHandler_Bound)
  }
  // update - raf
  // **************************************************************
  render() {
    if (this.enabled && this.loadAssetsLoaded) {
      const elapsedTime = this.clock.getElapsedTime()

      // update material
      this.figure.udpate()
      // Render
      this.renderer.render(this.scene, this.camera)
      // Call tick again on the next frame
    }
    this.rafID = window.requestAnimationFrame(this.render.bind(this))
  }

  enable() {
    this.enabled = true
    this.render()
  }
  // UnMount
  //****************************************************************** */
  disable() {
    // Kill the Request Animation Frame
    window.cancelAnimationFrame(this.rafID)
    window.removeEventListener("resize", this.ResizeHandler_Bound)
    this.figure.killEvent()
    this.enabled = false
  }
}

const gl = new Scene06()

// on lance à part du REACT le gl
if (typeof window !== `undefined`) {
  // gl.setup()
}

export default gl
