import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LANGUAGES from "../../Const/languages"
import { useStore } from "../../@core/scenes/mainStore"

export const SectionHistorique = props => {
  const LANG = useStore(state => state.language)

  let post = props.data
  if (post === undefined) return

  if (post.projet_historical.historicalDate === null) return null

  return (
    <section className="section-historique">
      <div className="contentMainContainer full-grid">
        <h2 className="section-title-medium">{LANGUAGES[LANG].history}</h2>
        <div className="historical-items">
          {post.projet_historical.historicalDate.map((value, index) => {
            return (
              <div key={index} className="item">
                <div className="date">{value.date}</div>
                <div className="description" dangerouslySetInnerHTML={{__html: value.description}}/>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
