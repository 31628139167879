import * as THREE from "three"
import vertexShader from "../shaders/disolve/disolve_vs.glsl"
import fragmentShader from "../shaders/disolve/disolve_scroll_fs.glsl"

export default class Figure {
  constructor(scene, texture, domRoot) {
    this.domRoot = domRoot
    this.texture = texture
    this.scene = scene
    this.isStarted = false
    this.sizes = new THREE.Vector2(
      this.domRoot.clientWidth,
      this.domRoot.clientHeight
    )
    window.addEventListener("resize", e => {
      this.onResize(e)
    })
    this.createMesh()
  }

  onResize(e) {
    this.sizes.x = this.domRoot.clientWidth
    this.sizes.y = this.domRoot.clientHeight
    // udpate material
    this.plane.material.uniforms.u_res.value.x = Math.round(this.sizes.x)
    this.plane.material.uniforms.u_res.value.y = Math.round(this.sizes.y)
  }

  createMesh() {
    const geometry = new THREE.PlaneGeometry(1, 1, 1, 1)
    const uniforms = {
      uTexture: { type: "t", value: this.texture },
      u_time: { type: "f", value: 0 },
      u_res: {
        value: new THREE.Vector2(
          this.domRoot.clientWidth,
          this.domRoot.clientHeight
        ),
      },
    }
    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      side: THREE.DoubleSide,
      defines: {
        PR: window.devicePixelRatio.toFixed(1),
      },
      alphaTest: 0.5,
      opacity: true,
      // blending: THREE.Blending,
    })

    this.plane = new THREE.Mesh(geometry, material)
    this.plane.scale.set(this.sizes.x, this.sizes.y, 1)
    this.scene.add(this.plane)
  }
  changeTexture(texture, domRoot) {
    this.domRoot = domRoot
    this.isStarted = false
    this.plane.material.uniforms.u_time.value = 0
    this.texture = texture
    this.plane.material.uniforms.uTexture.value = this.texture
  }
  udpate() {
    if (this.plane.material.uniforms.u_time.value < 1 && this.isStarted)
      this.plane.material.uniforms.u_time.value += 0.001
  }
  start() {
    this.plane.material.uniforms.u_time.value = 0
    this.isStarted = true
  }
}
