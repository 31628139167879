import * as THREE from "three"
import gsap from "gsap"
import vertexShader from "../shaders/disolve/disolve_vs.glsl"
import fragmentShader from "../shaders/disolve/disolve_fs.glsl"

export default class Figure {
  constructor(scene, texture, domContainer) {
    this.texture = texture
    this.scene = scene
    this.domContainer = domContainer
    this.sizes = new THREE.Vector2(
      this.domContainer.clientWidth,
      Math.round(this.domContainer.clientWidth * 0.538)
    )
    this.offset = new THREE.Vector2(0, 0)
    this.mouse = new THREE.Vector2(0, 0)

    const mouseMoveHandler = ev => {
      this.onMouseMove(ev)
    }

    this.domContainer.addEventListener("mousemove", mouseMoveHandler)
    this.killEvent = () => {
      this.domContainer.removeEventListener("mousemove", mouseMoveHandler)
    }
    this.createMesh()
  }
  onResize(width, height) {
    this.sizes.x = width
    this.sizes.y = height
    // udpate material
    this.plane.material.uniforms.u_res.value.x = this.sizes.x
    this.plane.material.uniforms.u_res.value.y = this.sizes.y
  }
  createMesh() {
    const geometry = new THREE.PlaneGeometry(1, 1, 1, 1)
    const uniforms = {
      uTexture: { type: "t", value: this.texture },
      // uTexture_hover: { type: "t", value: this.texture_hover },
      u_mouse: { value: this.mouse },
      u_time: { type: "f", value: 0 },
      u_res: {
        value: new THREE.Vector2(this.sizes.x, this.sizes.y),
      },
    }
    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      side: THREE.DoubleSide,
      defines: {
        PR: window.devicePixelRatio.toFixed(1),
      },
    })
    this.plane = new THREE.Mesh(geometry, material)
    this.plane.scale.set(this.sizes.x, this.sizes.y, 1)
    this.scene.add(this.plane)
  }

  onMouseMove(event) {
    // const y = window.scrollY

    gsap.to(this.mouse, 0.3, {
      x:
        ((event.clientX - this.domContainer.getBoundingClientRect().left) /
          this.domContainer.getBoundingClientRect().width) *
          2 -
        1,
      y:
        -(
          event.clientY /
          (this.domContainer.clientHeight +
            this.domContainer.getBoundingClientRect().top +
            130)
        ) *
          2 +
        1,
    })
  }
  udpate() {
    this.plane.material.uniforms.u_time.value += 0.01
  }
}
