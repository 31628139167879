import React, {useState} from "react"

export const VideoFull = props => {
  let post = props.data
  const [isMute, setMute] = useState(true)

  if (post === undefined) return

  if (post.media_video_full.mediaVideo === null) return null

  const urlVideo = post.media_video_full.mediaVideo.mediaItemUrl

  const toggleMute = () => {
    setMute(!isMute)
  }

  return (
    <div className="video-full-container" onClick={toggleMute}>
      <div className="unMuteText">Volume {isMute ? "OFF" : "ON"}</div>
      <video autoPlay={true} loop={true} playsInline={true} muted={isMute} >
        <source src={urlVideo} type="video/mp4" />
      </video>
    </div>
  )
}
